.body {
    width: 100%;
    height: 95vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 100%;

    form {
        position: relative;
        z-index: 1;
        min-width: 340px;
        background: #FFFFFF;
        max-width: 350px;
        margin: 0 auto 100px;
        padding: 50px 30px 30px 30px;
        text-align: center;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

        .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .logoImg {
            height: 80px;
            margin-bottom: 50px;
        }

        .inputText {
            margin: 0 0 15px;

            input {
                font-family: "Roboto", sans-serif;
                outline: 0;
                height: 35px;
                width: 100%;
                border: solid 1px #ccc;
                padding: 10px;
                box-sizing: border-box;
                font-size: 14px;
                border-radius: 5px;
            }

            .errorMessage {
                text-align: left;

                i {
                    color: var(--red-400);
                }
            }
        }

        .error {
            margin-bottom: 0.5rem;
        }

        button {
            width: 200px;
            outline: 0;
            border: 0;
            padding: 7px 10px;
            color: #FFFFFF;
            font-size: 12px;
            border-radius: 5px;
            margin-top: 20px;
            transition: filter 0.2s;
            background: var(--green-600);
            margin-bottom: 1rem;

            &:hover {
                filter: brightness(0.8);
            }

            &.loading {
                filter: brightness(0.8);
                cursor: not-allowed;
            }
        }

        .linkBack {
            display: flex;
            justify-content: center;
            margin-top: 0.5rem;

            color: var(--gray-800) svg {
                margin-top: 0.125rem;
                ;
            }
            svg{
              
                padding-top:1px;
                margin-right:2px;
            }
            &:hover {
                filter: brightness(0.5);
            }
        }

        .message {
            margin: 0 0 10px 0;
            color: #b3b3b3;
            font-size: 12px;
            text-align: left;
            transition: filter 0.2s;

            &:hover {
                filter: brightness(0.8);
            }
        }
    }
}



input::-webkit-input-placeholder { /* Chrome/Opera/Safari/Edge */
    color: var(--gray-400)
}