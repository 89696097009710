.sideBarContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100vh;
    position: fixed;
}

.sideBarSize {
    display: none;
}

.sideBarSizeProfileNewPage {
    display: none;
}

.sideBarMobile {
    width: 16.66666667%;
    background: #FFFFFF;
    border: solid 1px #6B7280;
    --tw-border-opacity: 1;
    border-color: rgba(229,231,235,var(--tw-border-opacity));
    padding: 0.75rem;
    display: block;
}

@media(min-width: 900px) {
    .sideBarSize {
        width: 16.66666667%;
        background: #FFFFFF;
        border: solid 1px #6B7280;
        --tw-border-opacity: 1;
        border-color: rgba(229,231,235,var(--tw-border-opacity));
        padding: 0.75rem;
        display: block;
    }
}

@media(max-width: 900px) {
    .sideBarMobile {
        width: 100%;
        background: #FFFFFF;
        border: solid 1px #6B7280;
        --tw-border-opacity: 1;
        border-color: rgba(229,231,235,var(--tw-border-opacity));
        padding: 0.75rem;
        display: block;
    }
}

.headerImg {
    height: 3rem;
}

.sideBarContent {
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.logOutButton {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 1.3rem;
    cursor: pointer;
}

.ulSidebar {

    list-style: none;
    margin-block-start: 0em;
    padding-inline-start: 0;
   
}

    .ulSidebar li
    {
        --tw-space-y-reverse: 0;
        margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
    }


        .itemSideBar {
            display: flex;
            align-items: center;
            margin-right: calc(0.75rem * var(--tw-space-x-reverse));
            margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
            color: #374151;
            padding: 0.5rem;
            border-radius: 0.375rem;
            font-weight: 500;
        }

    .itemSideBar:hover {
        background: #E5E7EB;
    }

.bg-current-sidebar {
    background: #E5E7EB;
}

.iconSidebar {
    margin-right: 0.7rem;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleSidebar {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.branch {
    margin: 0.5rem 0 0.5rem 0.8rem;
}

.cursorPointer{
    cursor: pointer;
}

.layoutChildren {
    --tw-border-opacity: 1;
    border-top: solid 1px rgba(229,231,235,var(--tw-border-opacity));
    width: 100%;
    height: calc(100vh - 5rem);
    overflow: auto
}

.layoutChildrenProfileNewPage {
    width: 100%;
    height: 90%;
    margin-left: 20px;
    margin-right: 20px;
    border: solid 1px #6B7280;
    --tw-border-opacity: 1;
    border-color: rgba(229,231,235,var(--tw-border-opacity));
    overflow: auto;
}

@media(min-width: 900px) {
    .layoutChildren {
        width: 83.333333%;
    }
}
/*header*/
.headerContainer {
    position: fixed;
    height: 3.5rem;
    left: 0px;
    right: 0px;
    top: 0px;
    padding: 1rem 1.5rem;
}

.headerContent {
    display: none;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.headerRight{
    display: flex;
        
}

.headerContent {
    display: none;
}

.headerImg {
    height: 3rem;
}

.headerMobileMenu {
    display: none;
    font-size: 1.7rem;
}


    .headerTop {
    padding-top: 5rem;
}

@media(min-width: 900px) {
    .headerContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
    }
}

.headerMenu {
    margin-top: 0.5rem;
}

@media(max-width: 899px) {
    .headerMobileMenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.headerIndex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.headerWelcome {
    display: flex;
    flex-direction: column;
    font-size: 0.80rem;
    margin-left:0.5rem;
}

.headerTitle {
    font-weight: 500;
    border-bottom: solid 2px #E5E7EB;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.headerTitleMobile {
    font-weight: 500;
    border-bottom: solid 2px #E5E7EB;
    font-size: 1rem;
    line-height: 1.75rem;
}

.headerEnd {
    display: flex;
    justify-content:space-between;
    white-space:nowrap;
}

.headerEnd a{
    margin: 6px 10px;
}
    .headerEnd a:hover {
        color: #059669;
    }

.headerEndChild {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.40rem 0;
    margin-right: 1rem;
    font-size: 0.8rem;
}

.headerCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    background: #059669;
    border-radius: 50%;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 0.80rem;
}
