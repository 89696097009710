.tabsContainer {
    width: 100%;
   height: calc(100% - 16.25rem)
}
@media(min-width: 1200px){
    .tabsContainer {
        height: calc(100% - 12rem)
    }
}
.tabsUl {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 0.5rem;
    list-style-type: none;
}

.tabsLi {
    margin-bottom: 0;
    width: 6rem;
}


.tabsTab {
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    display: block;
}

.tabsActive {
    color: #1F2937;
    background: #E5E7EB;
    border: solid 1px inherit;
    border-bottom: 0;
}

.tabsDisabled {
    color:#9CA3AF;
    background: #FFFFFF;
}

.tabsLine {
    width: 100%;
    height: 0.125rem;
    background: #E5E7EB;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
    
.tabContentContainer {
    display: flex;
    flex-direction:column;
    overflow-wrap: break-word;
    width: 100%;
    margin-bottom: 1.5rem;
    border-radius: 0.25rem;
}

.block {
    display: block;
}

.hidden {
    display: none;
}
.tabschildContent{
    height:100%;
}