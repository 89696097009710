

.container {

    form {
        margin: 1rem 1rem;

        .grid {
            display: grid;
            justify-items: start;

            .inputs {
                padding: 1rem 0;

                select {
                    margin: 0.10rem 0;
                }
            }
        }

        .spanFakeButton {
            font-size: 13px !important;
            font-family: Arial !important;
            display: inline-block;
            padding: 0.25rem 1rem;
            color: #1F2937;
            background-color: #FFF;
            border: 2px solid #D1D5DB;
            border-radius: 0.125rem;
            cursor: not-allowed
        }
    }
}
.buttons {
    display: flex;
    gap:1rem;
}

.formikErrors {
    color: red;
}

