.container {
    height: 100%;
    overflow: auto;
}

.fisrtContentBox {
    height: 100%;
}

.fisrtContentBoxBreak {
    height: 55%;
    overflow: auto;
}

.showTransfersTable {
    margin-top: 1rem;
    height: 45%;
    overflow: auto;
}
