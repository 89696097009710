.grid {
    padding:1rem;
    display: grid;
    justify-items: start;
    }

.checkBoxOld {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0.5rem 1rem;
    padding: 0 2.2rem;

}

.checkBox {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0.5rem 1.1rem;

    @media(max-width: 900px) {
        margin-right: 1.55rem;
    }
}

.checkBox label {
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
}

.button {
    display: flex;
    flex-direction: row;
    gap: 0.60rem;
    margin-top: 1rem;
    margin-right: 1.5rem;
    justify-content:end;

    @media(min-width: 900px) {
        margin-right: 1rem;
    }
}

.inputs {
    padding: 1px 0;
}