.container {
    height: 90%;

    .fisrtContentBox {
        height: 100%;
    }

    .fisrtContentBoxBreak {
        height: 60%;
        overflow: auto;
    }

    .detailsTable {
        margin-top: 1rem;
        height: 40%;
        overflow: auto;
    }
}
