

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";


* {
    font-size: 0.97em;
}
button, input, optgroup, select, textarea, label {
    font-size: 13px !important
}

.react-modal-overly {
    background: rgba(0, 0, 0, 0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}
.react-modal-content {
    width: 100%;
    max-width: 470px;
    min-height:180px;
    max-height: 576px;
    border: 0;
    background: #f0f2f5;
    padding: 10px;
    position: relative;
    border-radius: 0.24rem;
}
