.borderUploader {
    border: solid 2px #9CA3AF;
    margin: 0.5rem 0;
    padding: 1.5rem 4rem;
    border-style: dashed;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    transition: 0.1s;
}
    .borderUploader:hover{
        filter:brightness(0.8);
    }

    .border-green {
        border-color: #6EE7B7;
    }

.border-red {
    border-color: #EF4444;
}
