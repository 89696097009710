




.buttonComponent {
    color: #1F2937;
    background: #FFF;
    border: solid 2px #D1D5DB;
    border-radius: 0.125rem;
    padding: 0.25rem 1rem;
}

.buttonComponent:hover {
    background: #059669;
    color: #FFF
}

.lineBtn {
    width: 100%;
    padding: 0 0;
}