.FileListUl {
    margin: 1rem;
    list-style: none;
}

.FileListLi {
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
}

.fileinfo {
    display: flex;
    align-items:center;
    
}

.fileText {
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
}

    .fileText span {
        margin-top: 0.5rem;
        color: #D1D5DB;
    }

        .fileText span button {
            margin-left: 0.25rem;
            border: 0;
            background: transparent;
            color: #DC2626;
            font-weight: 600;
        }

.fileError {
    display: flex;
    align-items:center;
    margin-top:0.5rem;
}

.error {
    margin-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #DC2626;
}

.success {
    margin-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #10B981;
}