.header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    h2 {
        font-weight: 500;
        padding-bottom: 2px;
        border-bottom: solid 2px var(--gray-400);
    }

    svg {
        cursor: pointer;
    }
}

.withError {
    margin-top: 2.5rem;
}

.withOutError {
    margin-top: 5.5rem;
}

.content {
    display: flex;
    margin-top: 0.5rem;

    > div {
        display: flex;
        width: 100%;
        padding: 1.5rem;
        justify-content: center;
        gap: 6rem;
    }
}
