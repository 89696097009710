.header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    h2 {
        font-weight: 500;
        padding-bottom: 2px;
        border-bottom: solid 2px var(--gray-400);
    }

    svg {
        cursor: pointer;
    }
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;

    form {
        margin: 1rem 1rem;

        > div {
            display: grid;
            justify-items: start;

            > div {
                display: grid;
                gap: 0.05rem;
            }
        }

        .flexButton {
            display: flex;
            width: 100%;
            justify-content: end;
            padding: 1rem;
            ;
        }
    }
}

.containerCheckBoxInput {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-right: 1rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-left: 1.32rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: inherit;
}

.containerCheckBoxInput label {
    padding-right: 2px;
    font-weight: 600;
    color: #4B5563;
    padding: 0.20rem 0;
    font-size: 0.85rem;
    line-height: 1rem;
    margin-left: 1.77rem;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
}

.formikErrors {
    color: red;
}
