.container {
    height: 100%;
}

.divSelectCompany {
    padding: 1rem 2.5rem 2.5rem;
    width: 703px;
    display: flex;
    margin-left: 1rem;
    align-items: center;

    svg {
        margin-top: 0.40rem;
        cursor: pointer;
    }

    .selectCompany {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    @media(max-width: 900px) {
        width: 655px;
        margin-left: 1.65rem;
    }

    @media(max-width: 670px) {
        width: 480px;
    }
}

form {

    .gridCompanyInfo {
        display: grid;
        justify-items: start;
        margin-left: 1rem;
        margin-bottom: 1rem;

        > div {
            display: grid;
            grid-template-columns: repeat(1,1fr);

            @media(min-width: 670px) {
                grid-template-columns: repeat(2,1fr);
            }

            @media(min-width: 1230px) {
                grid-template-columns: repeat(3,1fr);
            }
        }

        .saveButton {
            width: 20px;
            justify-self: start;
            margin-top: 1rem;
            margin-left: 6.2rem;

            @media(max-width: 900px) {
                margin-left: 6.6rem;
            }
        }
    }
}

.containerCheckBoxInput {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-right: 1rem;
    margin-top: 0.2rem;
    margin-bottom: 0.5rem;
    margin-left: 1.1rem;
    font-size: 0.75rem;
    line-height: 0.5rem;
    font-family: inherit;

    @media(max-width: 900px) {
        margin-top: 0.3rem;
        margin-bottom: 0.2rem;
    }

    @media(max-width: 1230px) {
        margin-top: 0.3rem;
        margin-bottom: 0.2rem;
    }
}

.containerCheckBoxInput label {
    padding-right: 3.5px;
    font-weight: 600;
    color: #4B5563;
    font-size: 0.85rem;
    line-height: 1rem;
    padding-bottom: 2px;

    @media(max-width: 900px) {
        padding-right: 11px;
    }
}

.labelImplantation {
    margin-right: 1.1rem;
    margin-left: -0.3rem;
    margin-bottom: 0.2rem;
}

.inputSelectModality {
    grid-column: span 2;
    margin-left: 19px;

    @media(max-width: 900px) {
        width: 97%;
    }
}
