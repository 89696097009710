.textBoxContainer {
    height: 16.666667%;
    padding: 1rem 0;
    min-width: 300px;
}

.textBoxLabel {
    font-weight: 600;
    color: #4B5563;
    padding: 0.20rem 0;
    margin: 0.25rem;
    font-size: 0.85rem;
    line-height: 1rem;
}

.textBoxInput {
    display: block;
    width: 100%;
    height: 6rem;
    padding: 0.5rem 0.5rem;
    color: #4B5563;
    background: #FFFFFF;
    border: solid 0.05rem #D1D5DB;
    border-radius: 0.20rem;
    outline: none;
    font-family: inherit;
    resize:vertical;
}

.textBoxInput:focus {
    box-shadow: inset 0 0 0 0.10rem #93C5FD;
    outline:none;
}