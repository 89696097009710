
/*Loadgin */
.loadingContainer {
    display:flex;
    height: 100% ;
    width:100%;
    justify-content:center;
    align-items:center;
}
.loadingContent {
    display: flex;
    flex-direction: row;
}

/*General*/
.tableContainer {
    width: 100%;
    overflow: auto;
}

.tableContent {
    width: 100%;
    border-collapse: unset;
    border-spacing: 0;

}

.bg-gray-300 {
    background: #D1D5DB;
}

.text-gray-600 {
    color:#4B5563;
}

.font-bold {
    font-weight: 600;
}

.contendId {
    background: #9CA3AF;
    color: #FFFFFF;
    font-weight: 600;
}
/*Header*/


.tableHeader {
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:2rem;
}

.tableHeaderTitle{
    font-weight: 500;
    font-size: 0.95rem;
    padding:0.5rem 0.10rem 0.5rem 0.5rem;
}

.tableHeaderLength {
    font-size: 0.875rem;
    font-style: italic;
}

.tableHeaderButtons{
    display: flex;
    flex-direction: row;
    align-items:center
}

.tableHeaderButtonChild {
    margin: 0 0.5rem;

    font-size: 0.9rem;
}

/*thead*/
.tableHead {
    padding: 0.25rem 0.5rem;
    border-bottom: solid 0.10rem #E5E7EB;
    background: #DBEAFE;
    text-align: start;
    font-size: 0.85rem;
    line-height: 1rem;
    color: #374151;
    letter-spacing: 0.05rem;
    font-weight: 400
}


/*tbody*/
.morInfo {
    color: #2563EB;
    cursor: pointer;
    font-size: 0.60rem;
}

.tdata {
    padding: 0.40rem 0.3rem;
    white-space: nowrap;
    border-bottom: solid 0.05rem #E5E7EB;
    font-size: 0.80rem!important
}
.wrap:not(:first-child) {
    padding: 0.40rem 0.3rem;
    white-space: inherit;
    border-bottom: solid 0.05rem #E5E7EB;
    font-size: 0.80rem !important;
    border-left: dashed 1px #E5E7EB;


}
.wrap:first-child {
    border-bottom: solid 0.05rem #E5E7EB;
}

.tdataEditable {
    padding: 0.20rem 0.3rem;
    white-space: nowrap;
    border: solid 0.05rem #E5E7EB;
}

.tdataSelectInput {
    outline: none;
    width: 100%;
    height: 100%;
    padding: 0.15rem 0.15rem;
    overflow: hidden;
    border: 0;
    font-family: inherit;
}
.tdataTextArea {
    height: 1.2rem;
    font-family:inherit;
}


.lineButton {
    padding: 0.25rem 0.25rem;
    border-bottom: solid 0.05rem #E5E7EB;
    border-right: 0;
    border-left: solid 0.05rem #E5E7EB;
    cursor: pointer;
}

.lineButtonHidden {
    padding: 0.25rem 0.25rem;
    border-bottom: solid 0.05rem #E5E7EB;
    border-right: 0;
    border-left: solid 0.05rem #E5E7EB;
    /*cursor: pointer;*/
}

.lineButtonForEach {
    padding: 0.25rem 0.25rem;
    border-bottom: solid 0.05rem #E5E7EB;
    border-right: 0;
    border-left: 0;
    cursor: pointer;
}

.editButton {
    height: 100%;
    border: solid 0.05rem #E5E7EB;
}

/*Footer*/

.tableFooterContainer {
    height: 100%;
    width: 90%;
    position: fixed;
}


.tableFooterContent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: solid 0.10rem #E5E7EB;
}

.tablepageChanger {
    display: flex;
    flex-direction: row;
}

.tablepageTransaction {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.25rem;
    margin: 0.5rem 0.25rem;
    color: #374151;
    border-radius: 0.375rem;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.tableHovered:hover {
    color: #FFFFFF;
    background: #059669;
}

.curretPage {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    margin: 0 0.25rem;
    color: #374151;
    font-weight: 600;
    border-radius: 0.375rem;
}

.tableInputPageChanger {
    display: block;
    width: 1.8rem;
    margin-top:2px;
    padding: 0rem 0.25rem;
    text-align: center;
    border: solid 0.05rem #E5E7EB;
    color: #374151;
}

.tableBotomSpan {
    margin: 0 0.5rem;
    margin-top: 0px;
}
.tableLinesPerPageContainer {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
    margin: 0 0.25rem;
}
.tableInputLinesPerPage {
    display: block;
    width: 1.8rem;
    margin-top: 1px;
    padding: 0rem 0.25rem;
    text-align: center;
    border: solid 0.05rem #E5E7EB;
    color: #374151;
    margin-left:0.25rem;
}