.container {
    height: 99%;

    .fisrtContentBox {
        height: 100%;
    }

    .fisrtContentBoxBreak {
        height: 60%;
        overflow: auto;
    }

    .detailsTable {
        margin-top: 1rem;
        height: 40%;
        overflow: auto;
    }

    .divConfirmReset {
        color: rgba(4,120,87);

        :hover {
            color: rgba(16,185,129);
        }
    }

    .divCancelReset {
        color: rgba(220,38,38);

        :hover {
            color: rgba(248,113,113);
        }
    }
}
