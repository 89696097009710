body, html {
    font-family: system-ui, Roboto, Arial, Helvetica, sans-serif;
}

button, a {
    cursor: pointer
}

a {
    text-decoration: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    /*    red*/
    --red-100: #FEE2E2;
    --red-400: #F87171;
    --red-600: #DC2626;
    --red-800: #991B1B;
    /*    green*/
    --green-100: #D1FAE5;
    --green-400: #34D399;
    --green-600: #059669;
    --green-800: #065F46;
    /*    gray*/
    --gray-100: #F3F4F6;
    --gray-400: #9CA3AF;
    --gray-600: #4B5563;
    --gray-800: #1F2937;
    /*    blue*/
    --blue-100: #DBEAFE;
    --blue-400: #60A5FA;
    --blue-600: #2563EB;
    --blue-800: #1E40AF;
    /*    indigo*/
    --indigo-100: #E0E7FF;
    --indigo-400: #818CF8;
    --indigo-600: #4F46E5;
    --indigo-800: #3730A3;
    /*    purple*/
    --purple-100: #EDE9FE;
    --purple-400: #A78BFA;
    --purple-600: #7C3AED;
    --purple-800: #5B21B6;
    /*    pink*/
    --pink-100: #FCE7F3;
    --pink-400: #F472B6;
    --pink-600: #DB2777;
    --pink-800: #9D174D;
    /*    yellow*/
    --yellow-100: #FEF3C7;
    --yellow-400: #FBBF24;
    --yellow-600: #D97706;
    --yellow-800: #92400E;
}
a:-webkit-any-link {
    color: var(--gray-800);
}

