.header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    h2 {
        font-weight: 500;
        padding-bottom: 2px;
        border-bottom: solid 2px var(--gray-400);
    }

    svg {
        cursor: pointer;
    }
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;

    form {
        margin: 1rem 1rem;

        > div {
            display: grid;
            justify-items: start;

            > div {
                display: grid;
                gap: 0.05rem;
            }
        }

        .flexButton {
            display: flex;
            width: 100%;
            justify-content: end;
            padding: 1rem;
            ;
        }
    }
}

.formikErrors {
    color: red;
}
