.container {

    form {
        /*margin: 1rem;*/
        margin-top: 1rem;
        margin-left: 1rem;


        .grid {
            display: grid;
            justify-items: start;

            .inputAreaGrid {
                display: grid;
                grid-template-columns: 1fr;

                .openTfs {
                    display: flex;
                    margin-right: 1rem;
                    align-items: center;
                    justify-content: end;
                    cursor: pointer;
                    font-weight: 600;
                    transition: color 0.2s;

                    @media(max-width: 660px) {
                        margin-top: 1rem;
                        margin-right: 1rem;
                    }

                    &:hover {
                        color: var(--green-800);
                    }

                    svg {
                        font-size: 1rem;
                    }
                }

                @media(min-width: 660px) {
                    grid-template-columns: 1fr 1fr;
                }

                @media(min-width: 1200px) {
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }

        .spanFakeButton {
            font-size: 13px !important;
            font-family: Arial !important;
            display: inline-block;
            padding: 0.25rem 1rem;
            margin-right: 1rem;
            color: #1F2937;
            background-color: #FFF;
            border: 2px solid #D1D5DB;
            border-radius: 0.125rem;
            cursor: not-allowed
        }
    }



    .attachments {
        display: flex;
        justify-content: space-between;
        width: 65%;
        margin: 0 15px;

        &:hover {
            color: var(--green-800);
        }

        .spanText {
            display: block;
            font-weight: 600;
            margin-bottom: 0.4rem;
            margin-left: 0.3rem;
            color: var(--gray-800);
            font-size: 13px;
        }

        .spanSvg {
            display: flex;
            margin-right: 1.25rem;
            margin-top: 0.25rem;
            cursor: pointer;
        }
    }

    .contentAttachments {
        width: 64%;
        border: solid 1px #ccc;
        margin: 0 15px;
        height: 100%;
        min-height: 50px;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        min-width: 300px;

        span {
            font-size: 12px;
        }

        > div {
            margin: 0.5rem 1rem;
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(1, 1fr);


            @media (min-width:768px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (min-width: 1024px) {
                grid-template-columns: repeat(4, 1fr);
            }

            @media (min-width:1280px) {
                grid-template-columns: repeat(5, 1fr);
            }



            .flex {
                display: flex;
                cursor: pointer;

                &:hover {
                    color: var(--green-800);
                }

                svg {
                    margin-top: 0.25rem;
                    margin-left: 1rem;
                }
            }
        }
    }
}

.commentsContainer {
    margin: 0 0.10rem;

    form {
        > div {

            button {
                &:first-child {
                    margin-right: 1rem;
                }
            }
        }
    }

    .buttonBack {
        display: flex;
        width: 100%;
        justify-content: start;
        margin-top: 1rem;
        margin-left: 1rem;
    }
}

.formikErrors {
    color: red;
}