
.successAlert {
    background-color: #34D399;
    color: #111827;
    padding: 10px;
    border: solid 1px #111827;
    border-left: solid 4px #111827;
}
.dangerAlert {
    background-color: #FBCFE8;
    color: #831843;
    border: solid 1px #DB2777;
    border-left: solid 4px #9D174D;
}
.infoAlert {
    background-color: #FEF3C7;
    color: #78350F;
    border: solid 1px #78350F;
    border-left: solid 4px #78350F;
}
.alert {
    padding: 10px
}