.text-gray-message {
    color: #9CA3AF;
    font-weight: 600;
}


.text-green-message {
    color: #6EE7B7;
    font-weight: 500;
}

.text-red-message {
    color: #EF4444;
    font-weight: 500;
}