.container {
    height: 99%;
    display: block;

    .containerBlock {
        height: calc(100% - 2rem);

        .containerAssignedToMe {
            margin-top: 0.5rem;
            height: 67%;
            overflow: auto;
        }

        .containerNotAssigned {
            margin-top: 1.5rem;
            height: 33%;
            overflow: auto;
        }
    }
}
