.header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    h2 {
        font-weight: 500;
        padding-bottom: 2px;
        border-bottom: solid 2px var(--gray-400);
    }

    svg {
        cursor:pointer;
    }
}

.content {
    display: flex;
    width: 100%;
    padding: 2.5rem;
    height: 100%;
    justify-content: center;
    align-items: center;


    form {
        > div {
            margin-top: 0.5rem;
        }
    }
}
