
ul {
    list-style: none
}
.treeLine{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s;
    margin-top: 0.25rem;
}

    .treeLine:hover {
        color: #808080;
    }
    .treeLine span {
    margin-left: 5px;
}
.secondUl{
    margin-left:1rem;
}