.containerDateInput {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 1rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-family:inherit
}
.dateInputLabel {
    font-weight: 600;
    color: #4B5563;
    padding: 0.20rem 0;
    margin-right: 0.25rem;
    font-size: 0.85rem;
    line-height: 1rem;
}

.dateInput {
   
    /*background: #F9FAFB;*/
    border: solid 0.05rem #D1D5DB;
    border-radius: 0.125rem;
    height: 1.5rem;
    padding-left: 0.75rem;
    margin: 0 0.5rem;
    font-family: inherit;
    outline: none;
}

.dateInputWidthDefault {
    width: 12rem;
}

.dateInputWidthResized {
    width: 100%;
}

    .dateInput:focus {
        box-shadow: inset 0 0 0 0.10rem #93C5FD;
        outline: none;
    }

@media(min-width: 900px) {
    .dateInput {
        width: 100%;
        margin: 0 0 0 0;
    }
    .dateInputWidthDefault {
        width: 14rem;
    }

    .dateInputWidthResized {
        width: 100%;
    }
}
